<template>
  <v-snackbar
    :timeout="-1"
    :key="`worker-snackbar`"
    v-model="model"
  >
    A new version of the Inspiration Library is available
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        @click="updateWorker"
        style="letter-spacing: initial; text-transform: none;"
        color="blue blue-lightning-3"
      >
        Refresh
      </v-btn>
      <v-btn
        color="rgba(255, 255, 255, 0.87)"
        icon
        v-bind="attrs"
        @click="model = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  name: 'UpdateWorkerWidget',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    updateWorker() {
      const needUpdate = localStorage.getItem('il-need-worker-update')
      if (!needUpdate) return false
      try {
        localStorage.setItem('il-need-worker-update', false)
        window.worker.postMessage({ type: "SKIP_WAITING" })
      } catch {
        console.log('При попытке обновить worker произошла ошибка')
        console.log('Worker: ', window.worker)
      } finally {
        location.reload(true)
      }
    },
  }
}
</script>